import React from "react";
// import cloudIllustration from "../static/images/cloud-illustration.svg";
import demoLaptop from "../static/images/demo_laptop.png";
import { Header } from "../components/Header";
// import { IndexEmailForm } from "../components/IndexEmailForm";
import { Footer } from "../components/Footer";
import { Button } from "../components/Button";
import { ProductSectionCards } from "../components/ProductSectionCards";
import { IndexEmailForm } from "../components/IndexEmailForm";
import { PlatformSchema } from "../components/PlatformSchema";
import { BusinessValue } from "../components/BusinessValue";
import { AcquisitionAnnouncement } from "../components/AcquisitionAnnouncement";

const IndexPage = () => {
  return (
    <html lang="en">
      <head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta http-equiv="refresh" content="0; URL=https://www.thousandeyes.com/blog/cisco-announces-acquisition-of-codebgp" />
        <title>Code BGP</title>
      </head>

      <body>
      </body>
    </html>
  );
};

export default IndexPage;
